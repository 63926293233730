import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import useDatabaseViewModel from './DatabaseManagerViewModel'
import DatabaseSync from './widgets/DatabaseSync'
import './DatabaseManager.css'

const DatabaseManager = () => {
    
    const vm = useDatabaseViewModel()
    
    const databaseLinks = useMemo(() => ({
        '/dashboard/database': ['DATABASE', <div vm={vm}>TODO: useful overview/stats</div>],
        '/dashboard/database/sync': ['SYNC', <DatabaseSync vm={vm} />],
    }), [vm])
    
    return (
        
        <div className="DatabaseManager">
            
            <header className="w-full flex items-baseline justify-between content-baseline">
                
                <div className="flex flex-col">
                    <h4 className="text-xl text-dashboard-primary">
                        Database
                    </h4>
                </div>
                
                <div className="flex items-baseline justify-end content-baseline gap-4">
                    <div className="tabs tabs-boxed" role="tablist">
                        {Object.keys(databaseLinks).map(url => {
                            
                            const [label] = databaseLinks[url]
                            
                            return (
                                <Link
                                    key={url}
                                    className={url === vm.tab ? 'tab tab-active' : 'tab'}
                                    role="tab"
                                    to="/dashboard/database"
                                    onClick={e => {
                                        e.preventDefault()
                                        vm.setTab(url)
                                    }}>
                                    {label}
                                </Link>
                            )
                            
                        })}
                    </div>
                </div>
            
            </header>
            
            <div className="flex flex-col gap-4 mt-4">
                {databaseLinks[vm.tab][1]}
            </div>
        
        </div>
        
    )
    
}

export default DatabaseManager
