import api from '$lib/api'
// import { createQueryString } from '@utils'

export const listDatabaseModels = async () => {
    
    const res = await api.get('/admin/database/models')
    
    return res.data
    
}

/**
 * Syncs one or more database models
 *
 * @param {string[]} modelNames - The names of the models to sync
 * @param {SyncDatabaseModelsOptions} options - Options for syncing the models
 * @returns {Promise<*>}
 */
export const syncDatabaseModels = async (modelNames = [], options = { alter: true }) => {
    
    const res = await api.post('/admin/database/sync', {
        modelNames,
        ...options,
    })
    
    return res.data
    
}
